import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ApolloProvider } from "@apollo/client";
import {
  Button,
  CheckboxField,
  StatsCard,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@shoptet/ui";
import { formatDistance } from "date-fns";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { ToastContainer } from "react-toastify";
import { CreditModalIncrease } from "./elements/Admin/ModalCreditIncrease";
import { ErrorBoundary, ErrorFallbackComponent } from "./elements/ErrorBoundary";
import { formatIssueName } from "./elements/GmcIssues";
import { DisabledIcon, Icon } from "./elements/Icons";
import Loading from "./elements/Loading/Loading";
import ScrollToTop from "./elements/ScrollToTop";
import { SmallLoader } from "./elements/SmallLoader";
import {
  GmcIssueSeverityT,
  OrganizationStateT,
  useAdminGmcIssuesQuery,
  useAdminOrganizationLabelsQuery,
  useAdminOrganizationsQuery,
  useAdminSummaryQuery,
  useSetOrganizationLabelsMutation,
} from "./graphql/generated/graphql";
import { formatFloat, formatMoney, formatPercents } from "./i18n/formatNumbers";
import { useAppSettings } from "./providers/appSettingsProvider";
import { createClient } from "./providers/graphqlProvider";
import { Tab, TabWrapper } from "./ui/Tabs";
import { isInIframe } from "./utils/isInIframe";

const AdminNavigation = () => {
  return (
    <TabWrapper>
      <Tab data-test-id="nav-dashboard" to="/" exactMatch>
        Dashboard
      </Tab>
      <Tab data-test-id="nav-organizations" to="/organizations">
        Organizations
      </Tab>
      <Tab data-test-id="nav-gmc-issues" to="/gmc-issues">
        GMC Issues
      </Tab>
    </TabWrapper>
  );
};

type LabelOptionsPropsT = {
  labelsOptions: { label: string; value: string }[];
};

type AdminOrganizationsPagePropsT = {
  labelsExclude: boolean;
  selectedLabels: string[];
};

const Labels = ({ apiToken, labels, labelsOptions }: { apiToken: string; labels: string[] } & LabelOptionsPropsT) => {
  const [setLabels, { loading }] = useSetOrganizationLabelsMutation();

  return (
    <Creatable
      defaultValue={labels.map((label) => ({ value: label, label }))}
      isLoading={loading}
      menuPortalTarget={document.body}
      options={labelsOptions}
      placeholder=""
      isClearable
      isMulti
      onChange={(items) => {
        setLabels({
          context: {
            headers: {
              Authorization: `ApiToken ${apiToken}`,
            },
          },
          variables: { labels: items.map((item) => item.value) },
        });
      }}
    />
  );
};

const AdminOrganizationsPage = ({
  labelsExclude,
  labelsOptions,
  selectedLabels,
}: AdminOrganizationsPagePropsT & LabelOptionsPropsT) => {
  const { data, loading } = useAdminOrganizationsQuery({
    variables: {
      labels: selectedLabels,
      exclude: labelsExclude,
    },
  });
  if (loading) {
    return (
      <div>
        <h1>Shoptet Campaigns - Admin Organizations</h1>
        <AdminNavigation />
        <SmallLoader />
      </div>
    );
  }
  return (
    <div>
      <ScrollToTop />
      <h1>Shoptet Campaigns - Admin Organizations</h1>
      <AdminNavigation />
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader></TableHeader>
            <TableHeader>Shop ID</TableHeader>
            <TableHeader>Domain</TableHeader>
            <TableHeader>State</TableHeader>
            <TableHeader>
              <div style={{ textAlign: "right" }}>Days from activation</div>
            </TableHeader>
            <TableHeader>
              <div style={{ textAlign: "right" }}>Credit Balance</div>
            </TableHeader>
            <TableHeader>
              <div style={{ textAlign: "right" }}>Used Credits</div>
            </TableHeader>
            <TableHeader>
              <div style={{ textAlign: "right" }}>Spend Balance</div>
            </TableHeader>
            <TableHeader>
              <div style={{ textAlign: "right" }}>Used Spend</div>
            </TableHeader>
            <TableHeader>
              <div style={{ textAlign: "right" }}>Products count</div>
            </TableHeader>
            <TableHeader>
              <div style={{ textAlign: "right" }}>Disapproved products count</div>
            </TableHeader>
            <TableHeader>
              <div style={{ textAlign: "right" }}>Error</div>
            </TableHeader>
            <TableHeader>
              <div style={{ textAlign: "right" }}>Approved assets</div>
            </TableHeader>
            <TableHeader>Labels</TableHeader>
            <TableHeader>GMC Link</TableHeader>
            <TableHeader>GMC Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.organizations?.map((organization) => {
            const assetLines = organization.activeAssetLines.filter((assetLine) => !assetLine.draftState);
            const disapprovedAssetLines = assetLines.filter((assetLine) => assetLine.googleDisapproved);

            return (
              <TableRow key={organization.id} data-test-id={`organization-row-${organization?.shoptetId}`}>
                <TableCell>
                  {organization?.adminAppPath && (
                    <a href={organization.adminAppPath} target="_blank">
                      <Icon kind="preview-icon" />
                    </a>
                  )}
                </TableCell>
                <TableCell>{organization?.shoptetId}</TableCell>
                <TableCell>
                  {organization.shoptetUrl && (
                    <a href={organization.shoptetUrl} target="_blank">
                      {organization.shoptetUrl}
                    </a>
                  )}
                </TableCell>
                <TableCell>
                  {organization.state}{" "}
                  {organization.state === OrganizationStateT.WizardT ? organization.wizardStep : ""}
                </TableCell>
                <TableCell>
                  <div style={{ textAlign: "right" }}>
                    {organization.state === OrganizationStateT.WizardT || !organization.startedAt
                      ? "-"
                      : formatDistance(new Date(organization.startedAt), new Date(), { addSuffix: true })}
                  </div>
                </TableCell>
                <TableCell>
                  <div data-test-id="cell-creditBalance" style={{ textAlign: "right" }}>
                    {organization.adminApiToken && <CreditModalIncrease apiToken={organization.adminApiToken} />}
                    {organization.creditBalance ? formatMoney(organization.creditBalance) : "-"}
                  </div>
                </TableCell>
                <TableCell>
                  <div data-test-id="cell-usedCredits" style={{ textAlign: "right" }}>
                    {organization.creditBalance ? formatMoney(organization.usedCredits) : "-"}
                  </div>
                </TableCell>
                <TableCell>
                  <div data-test-id="cell-spendBalance" style={{ textAlign: "right" }}>
                    {organization.spendBalance ? formatMoney(organization.spendBalance) : "-"}
                  </div>
                </TableCell>
                <TableCell>
                  <div data-test-id="cell-usedSpend" style={{ textAlign: "right" }}>
                    {organization.usedSpend ? formatMoney(organization.usedSpend) : "-"}
                  </div>
                </TableCell>
                <TableCell>
                  <div data-test-id="cell-productsCount" style={{ textAlign: "right" }}>
                    {formatFloat(organization.productsCount)}
                  </div>
                </TableCell>
                <TableCell>
                  <div style={{ textAlign: "right" }}>{formatFloat(0)}</div>
                </TableCell>
                <TableCell>
                  <div style={{ textAlign: "right" }}>-</div>
                </TableCell>
                <TableCell>
                  <div style={{ textAlign: "right" }}>
                    {assetLines.length > 0
                      ? formatPercents(((assetLines.length - disapprovedAssetLines.length) / assetLines.length) * 100)
                      : "-"}
                  </div>
                </TableCell>

                <TableCell>
                  {organization && organization.adminApiToken && (
                    <Labels
                      apiToken={organization.adminApiToken}
                      labels={organization.labels}
                      labelsOptions={labelsOptions}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {organization.gmcId ? (
                    <a href={`https://merchants.google.com/mc/overview?a=${organization.gmcId}`} target="_blank">
                      GMC Link
                    </a>
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>
                  <div data-test-id="cell-spendBalance" style={{ textAlign: "center" }}>
                    {organization.gmcAccountSuspended ? <DisabledIcon /> : <Icon kind="disable-property" />}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const AdminGMCIssues = ({}) => {
  const [showNotTranslated, setShowNotTranslatedAll] = useState(false);
  const { t } = useTranslation("translation", { keyPrefix: "Components.GMCIssues" });
  const { data, loading } = useAdminGmcIssuesQuery();
  if (loading) {
    return (
      <div>
        <h1>Shoptet Campaigns - Admin GMC Issues</h1>
        <AdminNavigation />
        <SmallLoader />
      </div>
    );
  }
  return (
    <div>
      <ScrollToTop />
      <h1>Shoptet Campaigns - Admin GMC Issues</h1>
      <AdminNavigation />
      <CheckboxField
        checked={showNotTranslated}
        label="Show issues which are not shown to users"
        value="t"
        onChange={(event) => setShowNotTranslatedAll(event.target.checked)}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Severity</TableHeader>
            <TableHeader>Translation keys</TableHeader>
            <TableHeader>Issue</TableHeader>
            <TableHeader>Count</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.gmcIssues.map((issue) => {
            const color = issue.severity === GmcIssueSeverityT.ErrorT ? "#db1b4e" : "#FFAE00";
            const translationKey = `Components.GMCIssues.attribute-message.${issue.name}`;

            const translatedIssueName = formatIssueName(t, issue.name);

            if (translatedIssueName.length > 0 && showNotTranslated) {
              return null;
            }

            return (
              <TableRow key={issue.name} data-test-id={`gmc-issue-row-${issue.name}`}>
                <TableCell>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px" }}>
                    <Icon
                      kind={issue.severity === GmcIssueSeverityT.ErrorT ? "delete-item" : "warning"}
                      className={
                        issue.severity === GmcIssueSeverityT.ErrorT
                          ? "shoptet-icon-color-error"
                          : "shoptet-icon-color-warning"
                      }
                    />
                    <div>
                      <span style={{ color }}>
                        {issue.severity === GmcIssueSeverityT.ErrorT ? t("error issue") : t("warning issue")}
                      </span>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <pre>{translationKey}</pre>
                </TableCell>
                <TableCell>{translatedIssueName}</TableCell>
                <TableCell>{issue.count}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const AdminHomePage = ({ labelsExclude, selectedLabels }: AdminOrganizationsPagePropsT) => {
  const { data } = useAdminSummaryQuery({
    variables: {
      labels: selectedLabels,
      exclude: labelsExclude,
      dateFromYesterday: DateTime.utc().minus({ days: 1 }).startOf("day").toISO(),
      dateFromWeek: DateTime.utc().minus({ days: 1 }).startOf("week").toISO(),
      dateFromMonth: DateTime.utc().minus({ days: 1 }).startOf("month").toISO(),
      dateFromYear: DateTime.utc().minus({ days: 1 }).startOf("year").toISO(),
    },
  });
  return (
    <div>
      <ScrollToTop />
      <h1>Shoptet Campaigns - Admin Dashboard</h1>
      <AdminNavigation />
      <h2>Spend</h2>
      <div className="statsCardGrid">
        <StatsCard
          icon="inCart"
          title={"Yesterday's spend"}
          value={data?.adminSummary?.spendYesterday ? formatMoney(data?.adminSummary?.spendYesterday) : "-"}
        />
        <StatsCard
          icon="inCart"
          title={"Week's spend"}
          value={data?.adminSummary?.spendWeek ? formatMoney(data?.adminSummary?.spendWeek) : "-"}
        />
        <StatsCard
          icon="inCart"
          title={"Month's spend"}
          value={data?.adminSummary?.spendMonth ? formatMoney(data?.adminSummary?.spendMonth) : "-"}
        />
        <StatsCard
          icon="inCart"
          title={"Year's spend"}
          value={data?.adminSummary?.spendYear ? formatMoney(data?.adminSummary?.spendYear) : "-"}
        />
        <StatsCard
          icon="inCart"
          title={"All time spend"}
          value={data?.adminSummary?.spendAllTime ? formatMoney(data?.adminSummary?.spendAllTime) : "-"}
        />
      </div>
      <h2>Credit</h2>
      <div className="statsCardGrid">
        <StatsCard
          icon="inCart"
          title={"Yesterday's credit"}
          value={data?.adminSummary?.creditYesterday ? formatMoney(data?.adminSummary?.creditYesterday) : "-"}
        />
        <StatsCard
          icon="inCart"
          title={"Week's credit"}
          value={data?.adminSummary?.creditWeek ? formatMoney(data?.adminSummary?.creditWeek) : "-"}
        />
        <StatsCard
          icon="inCart"
          title={"Month's credit"}
          value={data?.adminSummary?.creditMonth ? formatMoney(data?.adminSummary?.creditMonth) : "-"}
        />
        <StatsCard
          icon="inCart"
          title={"Year's credit"}
          value={data?.adminSummary?.creditYear ? formatMoney(data?.adminSummary?.creditYear) : "-"}
        />
        <StatsCard
          icon="inCart"
          title={"All time credit"}
          value={data?.adminSummary?.creditAllTime ? formatMoney(data?.adminSummary?.creditAllTime) : "-"}
        />
      </div>

      <h2>Spend By tarif (Last Month)</h2>
      {(data?.adminSummary?.statsByTarifMonth || []).map((statsByTarif) => {
        return (
          <div key={statsByTarif.tarifName}>
            <h3>{statsByTarif.tarifName}</h3>
            <div className="statsCardGrid">
              <StatsCard
                icon="inCart"
                title={"Organizations count"}
                value={statsByTarif.organizationsCount ? formatFloat(statsByTarif.organizationsCount) : "-"}
              />
              <StatsCard
                icon="inCart"
                title={"Total Spend"}
                value={statsByTarif.spend ? formatMoney(statsByTarif.spend) : "-"}
              />
              <StatsCard
                icon="inCart"
                title={"Average spend"}
                value={statsByTarif.averageSpend ? formatMoney(statsByTarif.averageSpend) : "-"}
              />
              <StatsCard
                icon="inCart"
                title={"Total Credit"}
                value={statsByTarif.credit ? formatMoney(statsByTarif.credit) : "-"}
              />
              <StatsCard
                icon="inCart"
                title={"Income"}
                value={statsByTarif.income ? formatMoney(statsByTarif.income) : "-"}
              />
            </div>
          </div>
        );
      })}
      <h2>Spend By tarif (All time)</h2>
      {(data?.adminSummary?.statsByTarifAllTime || []).map((statsByTarif) => {
        return (
          <div key={statsByTarif.tarifName}>
            <h3>{statsByTarif.tarifName}</h3>
            <div className="statsCardGrid">
              <StatsCard
                icon="inCart"
                title={"Organizations count"}
                value={statsByTarif.organizationsCount ? formatFloat(statsByTarif.organizationsCount) : "-"}
              />
              <StatsCard
                icon="inCart"
                title={"Total Spend"}
                value={statsByTarif.spend ? formatMoney(statsByTarif.spend) : "-"}
              />
              <StatsCard
                icon="inCart"
                title={"Average spend"}
                value={statsByTarif.averageSpend ? formatMoney(statsByTarif.averageSpend) : "-"}
              />
              <StatsCard
                icon="inCart"
                title={"Total Credit"}
                value={statsByTarif.credit ? formatMoney(statsByTarif.credit) : "-"}
              />
              <StatsCard
                icon="inCart"
                title={"Income"}
                value={statsByTarif.income ? formatMoney(statsByTarif.income) : "-"}
              />
            </div>
          </div>
        );
      })}
      <h2>Global Organizations count</h2>
      <div className="statsCardGrid">
        <StatsCard
          icon="inCart"
          title="all"
          value={data?.adminSummary?.allOrganizations ? formatFloat(data?.adminSummary?.allOrganizations) : "-"}
        />
        <StatsCard
          icon="inCart"
          title="Organizations after wizard"
          value={
            data?.adminSummary?.allAfterWizardOrganizations
              ? formatFloat(data?.adminSummary?.allAfterWizardOrganizations)
              : "-"
          }
        />
        <StatsCard
          icon="inCart"
          title="Active"
          value={data?.adminSummary?.activeOrganizations ? formatFloat(data?.adminSummary?.activeOrganizations) : "-"}
        />
        <StatsCard
          icon="inCart"
          title="Paused"
          value={data?.adminSummary?.pausedOrganizations ? formatFloat(data?.adminSummary?.pausedOrganizations) : "-"}
        />
      </div>
      <h2>Wizard</h2>
      <div className="statsCardGrid">
        <StatsCard
          icon="inCart"
          title="First Page"
          value={
            data?.adminSummary?.firstPageOrganizations ? formatFloat(data?.adminSummary?.firstPageOrganizations) : "-"
          }
        />
        <StatsCard
          icon="inCart"
          title="Suggestion Generation"
          value={
            data?.adminSummary?.suggestionGenerationOrganizations
              ? formatFloat(data?.adminSummary?.suggestionGenerationOrganizations)
              : "-"
          }
        />
        <StatsCard
          icon="inCart"
          title="Image Selection"
          value={
            data?.adminSummary?.imageSelectionOrganizations
              ? formatFloat(data?.adminSummary?.imageSelectionOrganizations)
              : "-"
          }
        />
        <StatsCard
          icon="inCart"
          title="Text Selection"
          value={
            data?.adminSummary?.textSelectionOrganizations
              ? formatFloat(data?.adminSummary?.textSelectionOrganizations)
              : "-"
          }
        />
        <StatsCard
          icon="inCart"
          title="Check"
          value={data?.adminSummary?.checkOrganizations ? formatFloat(data?.adminSummary?.checkOrganizations) : "-"}
        />
        <StatsCard
          icon="inCart"
          title="Finish"
          value={
            data?.adminSummary?.finishWizardOrganizations
              ? formatFloat(data?.adminSummary?.finishWizardOrganizations)
              : "-"
          }
        />
      </div>

      <h2>Income & Spend results</h2>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Date</TableHeader>
            <TableHeader>
              <div style={{ textAlign: "right" }}>Organizations count</div>
            </TableHeader>
            <TableHeader>
              <div style={{ textAlign: "right" }}>Spend</div>
            </TableHeader>
            <TableHeader>
              <div style={{ textAlign: "right" }}>Credit</div>
            </TableHeader>
            <TableHeader>
              <div style={{ textAlign: "right" }}>Income</div>
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {(data?.adminSummary?.statsByMonth || []).map((statByMonth) => (
            <TableRow key={statByMonth.date}>
              <TableCell>{statByMonth.date}</TableCell>
              <TableCell>
                <div style={{ textAlign: "right" }}>{formatFloat(statByMonth.organizationsCount)}</div>
              </TableCell>
              <TableCell>
                <div style={{ textAlign: "right" }}>{formatMoney(statByMonth.spend)}</div>
              </TableCell>
              <TableCell>
                <div style={{ textAlign: "right" }}>{formatMoney(statByMonth.credit)}</div>
              </TableCell>
              <TableCell>
                <div style={{ textAlign: "right" }}>{formatMoney(statByMonth.income)}</div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export type AuthorizationGuardPropsT = {
  currentUrl: string;
};

const AuthorizationGuard = ({ currentUrl }: AuthorizationGuardPropsT) => {
  const [selectedLabels, setSelectedLabels] = useState(["test"]);
  const [labelsExclude, setLabelsExclude] = useState(true);
  const { data, error, loading } = useAdminOrganizationLabelsQuery({
    fetchPolicy: "no-cache",
  });

  if (error) {
    return <ErrorFallbackComponent />;
  }
  if (loading) {
    return <Loading />;
  }

  const labelsOptions = data?.organizationLabels?.map((label) => ({ value: label.name, label: label.name })) || [];

  return (
    <div>
      <div
        style={{
          padding: "30px",
          zIndex: 100,
          position: "relative",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <h2 style={{ margin: 0 }}>Filter</h2>
        <Button disabled={!labelsExclude} variant="muted" onClick={() => setLabelsExclude(false)}>
          {!labelsExclude ? "Included" : "Include"}
        </Button>
        <Button disabled={labelsExclude} variant="muted" onClick={() => setLabelsExclude(true)}>
          {labelsExclude ? "Excluded" : "Exclude"}
        </Button>

        <div style={{ flexGrow: 1 }}>
          <Select
            defaultValue={selectedLabels.map((label) => ({ value: label, label }))}
            options={labelsOptions}
            placeholder="Filter labels"
            isClearable
            isMulti
            onChange={(items) => setSelectedLabels(items.map(({ value }) => value))}
          />
        </div>
      </div>
      <ScrollToTop />
      <Routes>
        <Route path={currentUrl}>
          <Route element={<AdminHomePage labelsExclude={labelsExclude} selectedLabels={selectedLabels} />} index />
          <Route element={<AdminGMCIssues />} path="/gmc-issues" />
          <Route
            path="/organizations"
            element={
              <AdminOrganizationsPage
                labelsExclude={labelsExclude}
                labelsOptions={labelsOptions}
                selectedLabels={selectedLabels}
              />
            }
          />
        </Route>
      </Routes>
    </div>
  );
};

function Admin() {
  const { apiToken, apiUri, currentUrl } = useAppSettings();

  return (
    <div style={{ padding: isInIframe() ? 0 : "40px" }}>
      <ErrorBoundary>
        <BrowserRouter basename="/admin">
          <ApolloProvider client={createClient({ apiUri, apiToken })}>
            <AuthorizationGuard currentUrl={currentUrl} />
            <ToastContainer />
          </ApolloProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </div>
  );
}

export default Admin;
